@import url(https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;700;800&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  max-width: 100vw;
  min-width: 100vw;
  width: 100%;

  overflow-x: hidden;

  background: #F6F6F6;
  font-family: 'Manrope', sans-serif;
}

#root {
  max-width: 100vw;
  min-width: 100vw;
  width: 100%;
}



